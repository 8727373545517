//@flow
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

export default ({
  className,
  style,
  color,
  ...props
}: {
  className?: string,
  style?: any,
  props: any,
  color?: string,
}) => {
  color = color ? color : 'white';
  return (
    <IconButton
      className={className}
      {...props}
      color="secondary"
      style={{ fill: color, padding: 10, width: 54, height: 54, ...style }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 34 34"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>Menu</title>
        <path
          transform="translate(4.2 8.5)"
          id="a"
          fillRule="evenodd"
          d="M0 15.583C0 16.366.634 17 1.417 17h22.666c.783 0 1.417-.634 1.417-1.417 0-.782-.634-1.417-1.417-1.417H1.417C.634 14.166 0 14.8 0 15.583zM0 8.5c0 .783.634 1.417 1.417 1.417h22.666c.783 0 1.417-.634 1.417-1.417 0-.782-.634-1.417-1.417-1.417H1.417C.634 7.083 0 7.718 0 8.5zM1.417 0C.634 0 0 .634 0 1.417 0 2.2.634 2.833 1.417 2.833h22.666c.783 0 1.417-.634 1.417-1.416C25.5.634 24.866 0 24.083 0H1.417z"
        />
      </svg>
    </IconButton>
  );
};

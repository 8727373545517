// @flow
import { Link, navigate } from 'gatsby';
import React from 'react';
import type { Node } from 'react';

export default ({
  to,
  children,
  style,
  ...rest
}: {
  to: string,
  children: Node,
  style?: any,
  rest?: any,
}) => {
  const isExternal =
    typeof to === 'string' &&
    (to.includes('http://') || to.includes('https://'));
  return isExternal ? (
    <a style={{ textShadow: 'none', ...style }} href={to} {...rest}>
      {children}
    </a>
  ) : (
    <Link style={{ textShadow: 'none', ...style }} to={to} {...rest}>
      {children}
    </Link>
  );
};

export const navigateTo = navigate;

//@flow
import Card from '@material-ui/core/Card';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import type { ImageType, ImageSharp } from '../types';

export default ({
  src,
  className,
  image,
  style,
}: {
  src?: string,
  className?: string,
  style?: any,
  image?: ImageSharp,
}) => {
  const img = image ? image : null;
  return (
    <StyledCard style={style} className={className}>
      {img && img.sizes ? (
        <ImageComponent
          fluid={img && img.sizes ? img.sizes : console.log('NOPE')}
        />
      ) : (
        <ImageComponentOrig src={image ? image.resolutions.base64 : src} />
      )}
    </StyledCard>
  );
};

const ImageComponent = styled(Img)`
  && {
    position: relative;
    width: 100%;
    height: auto;
  }
`;

const ImageComponentOrig = styled.img`
  && {
    position: relative;
    width: 100%;
    height: auto;
  }
`;

const StyledCard = styled(Card)`
  && {
    z-index: 200;
    line-height: 0;
    overflow: hidden;
    margin: 20px;
    max-width: 450px;
    align-self: center;
    border-radius: 4px;
  }
`;

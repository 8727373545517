// @flow
import React from 'react';

export default ({ style, ...props }: { style?: any, props?: any }) => (
  <svg
    style={{ height: '100%', width: '100%', padding: 6, ...style }}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <use xlinkHref="#b" transform="rotate(45.11 -2.723 10.164)" />
    <use xlinkHref="#b" transform="rotate(135.004 8.237 7.125)" />
    <defs>
      <path
        id="b"
        fillRule="evenodd"
        d="M1 0C.448 0 0 .448 0 1s.448 1 1 1h16c.552 0 1-.448 1-1s-.448-1-1-1H1z"
      />
    </defs>
  </svg>
);

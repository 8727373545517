// @flow
import Card from '@material-ui/core/Card';
import React from 'react';
import { Motion, spring } from 'react-motion';
import styled from 'styled-components';
import type { Node } from 'react';

type State = { isOpen: boolean };

type Props = { children: Node, toggle?: Node };

class HoverMenu extends React.Component<Props, State> {
  state = { isOpen: false };

  open = () => this.setState(state => ({ isOpen: true }));
  close = () => this.setState(state => ({ isOpen: false }));

  render() {
    return (
      <Wrap
        onMouseEnter={this.open}
        onMouseLeave={this.close}
        isOpen={this.state.isOpen}
        close={this.close}
        toggle={this.props.toggle}
        {...this.props}
        id="hover"
      >
        {this.props.toggle}

        <Motion
          defaultStyle={{ opacity: 0 }}
          style={{
            opacity: this.state.isOpen ? spring(1) : 0,
          }}
        >
          {interpolatingStyle => (
            <Hover
              style={{
                ...interpolatingStyle,
                display: this.state.isOpen ? 'block' : 'none',
              }}
            >
              <Triangle />
              <MenuWrap raised={true}>{this.props.children}</MenuWrap>
            </Hover>
          )}
        </Motion>
      </Wrap>
    );
  }
}

export default HoverMenu;

const Wrap = styled.div`
  && {
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }
`;

//   overflow: hidden;

const Hover = styled.div`
  && {
    position: absolute;
    display: flex;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const triangleBorder = 10;
const Triangle = styled.div`
  && {
    height: 0;
    width: 0;
    border-left: ${triangleBorder}px solid transparent;
    border-right: ${triangleBorder}px solid transparent;
    border-bottom: ${triangleBorder}px solid white;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

// const MenuWrap = styled.div`
//   background: white;
//   border-radius: 2px;
//   padding: 10px;
//   display: block;
//   flex-direction: column;
//   margin: 20px;
//   margin-top: ${triangleBorder}px;
//   overflow: hidden;
//   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
// `;

const MenuWrap = styled(Card)`
  && {
    margin: 20px;
    margin-top: ${triangleBorder}px;
  }
`;

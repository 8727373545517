//@flow
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { Link, navigate } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { spring } from "react-motion";
import Transition from "react-motion-ui-pack";
import styled, { css } from "styled-components";
import Arrow from "../icons/Arrow";
import Logo from "../icons/Logo";
import MenuIcon from "../icons/Menu.js";
import {
  LARGE_BREAKPOINT,
  MAX_WIDTH,
  MEDIUM_BREAKPOINT,
  query,
  XL_BREAKPOINT
} from "../style";
import { purple } from "../style/colors";
import { patientPortalLink, telUrl } from "../utils/constants";
import Button from "./button";
import Hover from "./Hover";
import ImageCard from "./ImageCard";
import Menu from "./Menu";
import type { ImageSharp } from "../types";
import newPatientForms from "../assets/Spine Idaho New Patient Form.pdf";
import Coronavirus from "./Coronavirus";

const MENU_BREAKPOINT = 950;

type State = {
  menuOpen: boolean
};

// If it is transparent, make it thin and have no background.
type Props = {
  short?: boolean,
  thin?: boolean,
  simpleBackground?: string,
  centered?: boolean,
  title?: string,
  subtitle?: string,
  cta?: null | "Make an appointment" | "Give us a call",
  metaTitle?: boolean,
  mainImage?: ImageSharp,
  secondImage?: ImageSharp,
  thirdImage?: ImageSharp,
  light?: boolean,
  call?: boolean
};

export default class Header extends React.Component<Props, State> {
  state: State = {
    menuOpen: false
  };

  toggleMenu = () => {
    this.setState(state => ({ ...state, menuOpen: !state.menuOpen }));
  };

  render() {
    const {
      title = "Let's get you back into life",
      subtitle = "At the Spine Institue of Idaho, our patients come first. Every year we help thousands of people manage their pain so they can get back to doing what they love.",
      cta = "Make an appointment",
      metaTitle = true,
      mainImage,
      secondImage,
      thirdImage,
      simpleBackground = null,
      centered = false,
      short = false,
      light = false,
      thin = false,
      call = true
    } = this.props;

    const color = simpleBackground && !light ? "#444F5A" : "white";
    const random = Math.random() * 20;
    const { menuOpen } = this.state;
    const giveCall = () =>
      window && window.innerWidth < 600
        ? (window.location.href = telUrl)
        : navigate("/contact");

    return (
      <div>
        <Transition
          component={false} // don't use a wrapping component
          appear={{ opacity: 0 }}
          enter={{
            opacity: spring(1, { stiffness: 450, damping: 26 })
          }}
          leave={{
            opacity: 0
          }}
        >
          {menuOpen && <Menu key="menu" closeMenu={this.toggleMenu} />}
        </Transition>
        <Wrapper
          thin={thin}
          short={short}
          random={random}
          simpleBackground={simpleBackground}
        >
          <Helmet>
            <meta name="description" content={subtitle} />
            {/* title && metaTitle && <title>{title}</title> */}
          </Helmet>
          <MaxWidth short={short} thin={thin}>
            <TopBar color={color}>
              <LogoLink to="/">
                <StyledLogo color={color} />
              </LogoLink>
              <BottomLine thin={thin} style={{ background: color }} />
              <MenuButton onClick={this.toggleMenu} color={color} />
              <DesktopNav style={{ color: color }}>
                <DesktopNavLink color={color} to="/conditions">
                  CONDITIONS
                </DesktopNavLink>
                <DesktopNavLink color={color} to="/services">
                  SERVICES
                </DesktopNavLink>
                {/* <DesktopNavLink to="/get-to-know-us">
                  Get to know us
                </DesktopNavLink> */}
                <Hover
                  toggle={
                    <DesktopNavLinkFake>
                      ABOUT US <ArrowDropDown />
                    </DesktopNavLinkFake>
                  }
                >
                  <List>
                    <ListItem component={Link} to="/team" button>
                      <ListItemTextPurple primary="Meet our team" />
                    </ListItem>
                    <ListItem component={Link} to="/facilities" button>
                      <ListItemTextPurple primary="Explore the facilities" />
                    </ListItem>
                    <ListItem component={Link} to="/philosophy-of-care" button>
                      <ListItemTextPurple primary="Philosophy of care" />
                    </ListItem>
                    <ListItem component={Link} to="/referral" button>
                      <ListItemTextPurple primary="Providers: Refer a patient" />
                    </ListItem>
                  </List>
                </Hover>
                <Hover
                  toggle={
                    <DesktopNavLinkFake>
                      PATIENT EXPERIENCE <ArrowDropDown />
                    </DesktopNavLinkFake>
                  }
                >
                  <List>
                    <ListItem component={Link} to="/consultation" button>
                      <ListItemTextPurple primary="Consultation" />
                    </ListItem>
                    {/* <ListItem
                      onClick={() => navigate('/second-opinion-program')}
                      button
                    >
                      <ListItemTextPurple primary="Second opinion program" />
                    </ListItem> */}
                    <ListItem
                      component={Link}
                      to="/procedure-and-recovery"
                      button
                    >
                      <ListItemTextPurple primary="Procedure and recovery" />
                    </ListItem>
                    <ListItem
                      component={Link}
                      to="/payment-and-insurance"
                      button
                    >
                      <ListItemTextPurple primary="Payment and insurance" />
                    </ListItem>
                    <ListItem component="a" href={newPatientForms} button>
                      <ListItemTextPurple primary="New Patient Forms" />
                    </ListItem>
                  </List>
                </Hover>

                <DesktopNavLink color={color} to="/contact">
                  CONTACT US
                </DesktopNavLink>
              </DesktopNav>
              <PatientPortalLink
                target="_blank"
                href={patientPortalLink}
                thin={thin}
              >
                PATIENT PORTAL
                <SmallArrow color={color} />
              </PatientPortalLink>
            </TopBar>
            <Coronavirus thin={thin} />
            {title && !thin && (
              <Content centered={centered}>
                <Title
                  style={{
                    color,
                    textAlign: centered ? "center" : "left",
                    alignSelf: centered ? "stretch" : "flex-start"
                  }}
                >
                  {title}
                </Title>
                <Subtitle
                  style={{
                    color,
                    textAlign: centered ? "center" : "left",
                    alignSelf: centered ? "stretch" : "flex-start",
                    maxWidth: "100%"
                  }}
                >
                  {subtitle}
                </Subtitle>
                {!!cta && !thin && (
                  <div>
                    <PrimaryButton
                      onClick={
                        cta === "Give us a call"
                          ? giveCall
                          : () => navigate("/request-an-appointment")
                      }
                      color="primary"
                      label={cta}
                      arrow
                    />
                    {cta !== "Give us a call" && call && (
                      <SecondaryButton
                        label="Give us a call"
                        onClick={giveCall}
                      />
                    )}
                  </div>
                )}
              </Content>
            )}
            {!simpleBackground && !thin && <FirstImageCard image={mainImage} />}
            {!simpleBackground && !thin && (
              <SecondImageCard image={secondImage} />
            )}
            {!simpleBackground && !thin && (
              <ThirdImageCard image={thirdImage} />
            )}
          </MaxWidth>
          {/* <ThinWrapper thin={thin}> */}
          {/* <Stripes
              short={short}
              random={random}
              simpleBackground={simpleBackground}
            > */}
          {/* {!simpleBackground && <Stripe1 />} */}
          {/* {!simpleBackground && <Stripe2 />} */}
          {/* {!simpleBackground && <Stripe3 />} */}
          {/* {!simpleBackground && <Stripe4 />} */}
          {/* </Stripes> */}
          {/* </ThinWrapper> */}
        </Wrapper>
      </div>
    );
  }
}

const ListItemTextPurple = styled(ListItemText)`
  && {
    h3 {
      color: ${purple[500]};
    }
  }
`;
const Title = styled.h1`
  && {
    color: white;
    margin: 0px;
    ${query(LARGE_BREAKPOINT)`
      font-size: 40px;
      margin-bottom: 50px;
      margin: 0px;
    `};
  }
`;

const Subtitle = styled.p`
  && {
    color: rgba(255, 255, 255, 0.85);
    margin: 0px;
    margin-bottom: 30px;
    margin-top: 20px;
    font-size: 18px;
    ${query(MEDIUM_BREAKPOINT)`
      
    `} ${query(LARGE_BREAKPOINT)`
      font-size: 20px;
    `};
    ${query(XL_BREAKPOINT)`
      font-size: 22px;
    `};
  }
`;

const Content = styled.div`
  && {
    flex: 1;
    top: 80px;
    z-index: 100;
    margin: 30px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    max-width: 500px;

    height: 100%;
    max-height: 35%;

    ${query(MEDIUM_BREAKPOINT)`
    flex: 0.5;
    width: 60%;
    max-width: 510px;
    margin-top: 60px;
  `};
    ${query(LARGE_BREAKPOINT)`
    max-width: 560px;
    margin-top: 90px;
  `};
    ${query(XL_BREAKPOINT)`
     max-width: 530px;
  `};

    ${props =>
      props.centered &&
      `
      align-self: center;
      max-width: 800px !important;
      width: initial !important;
      width: initial;
      justify-content: flex-start;
  `};
    ${({ short }) => short && "margin-bottom: 50px;"};
  }
`;

const MaxWidth = styled.div`
  && {
    position: relative;
    display: block;
    z-index: 40;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: ${MAX_WIDTH}px;
    margin: auto;
    padding-bottom: 10px;

    ${({ short, thin }) =>
      !short &&
      query(MEDIUM_BREAKPOINT)`
    height: calc(700px + ${coronavirusMedium}px);
  `};
    ${({ short }) =>
      !short &&
      query(XL_BREAKPOINT)`
    height: clac(900px + ${coronavirusXL}px);
  `};

    ${props =>
      props.thin &&
      `
        height: 115px !important;
        display: block;
      `};
    ${props =>
      props.thin &&
      query(MEDIUM_BREAKPOINT)`
      height: 150px !important;
  `} ${({ short }) =>
      !short &&
      query(LARGE_BREAKPOINT)`
    ${props =>
      props.thin &&
      `
      
    `}
  `};
  }
`;

const ThinWrapper = styled.div`
  && {
    background: black;
    ${({ thin }) =>
      thin &&
      `
        height: 115px;
        position: absolute;
        width: 100%;
        top: 0px;
        overflow: hidden;
    `};
    ${props =>
      props.thin &&
      query(MEDIUM_BREAKPOINT)`
        height: 150px;
      `};
  }
`;

const Wrapper = styled.div`
  && {
    background: linear-gradient(
      ${props => props.random + 150}deg,
      #5533ff 15%,
      #8205ff 50%,
      #ffc1a6 94%
    );
    ${({ simpleBackground }) =>
      simpleBackground && `background: ${simpleBackground}`};
    transition: all 0.3s ease-in-out;
    z-index: 40;
    ${props =>
      props.thin &&
      `
    z-index: 5;
    position: relative;
    margin-bottom: 0px !important;
  `};
  }
`;
/**${query(LARGE_BREAKPOINT)`
    margin-bottom: 200px;
  `}; */

const TopBar = styled.nav`
  && {
    position: relative;
    z-index: 300;
    height: 115px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: ${({ color }) => color};
    ${query(LARGE_BREAKPOINT)`
    height: 140px;
  `};
  }
`;

const LogoLink = styled(Link)`
  && {
    background-image: none;
    text-shadow: none;
  }
`;

const StyledLogo = styled(Logo)`
  && {
    margin-top: 5px;
    height: 90px;
    width: auto;
    padding: 0px;
    margin-left: 20px;

    ${query(MEDIUM_BREAKPOINT)`
      height: 110px;
      margin-top: 20px;
    `};

    ${query(LARGE_BREAKPOINT)`
    height: 130px;
    margin-top: 10px;
  `};
  }
`;

const BottomLine = styled.div`
  && {
    position: absolute;
    bottom: 0px;
    height: 1px;
    width: calc(100% - 40px);
    margin: 0px 20px;
    opacity: 0.31;

    ${query(MEDIUM_BREAKPOINT)`
      display: none;
    `};
    ${props => props.thin && "display: none;"};
  }
`;

const DesktopNav = styled.nav`
  && {
    display: none;
    font-weight: 400;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin: 30px 20px 0px 50px;
    color: ${props => props.color};

    ${query(MENU_BREAKPOINT)`
    display: flex;
  `};
  }
`;

const DesktopNavLinkStyle = css`
  font-size: 17px;
  color: inherit;
  text-decoration: none;
  background: none;
  text-shadow: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const DesktopNavLink = styled(Link)`
  && {
    ${DesktopNavLinkStyle};
    &:hover {
      box-shadow: inset 0 -4px 0;
    }
  }
`;
const DesktopNavLinkFake = styled.a`
  && {
    ${DesktopNavLinkStyle};
    display: flex;
  }
`;

const PatientPortalLink = styled.a`
  && {
    position: absolute;
    right: 20px;
    top: 15px;
    color: inherit;
    background: none;
    text-decoration: none;
    text-shadow: none;
    display: none;
    justify-content: center;
    align-items: center;
    line-height: 17px;

    &:hover {
      opacity: 0.8;
      box-shadow: 0 4px 0;
    }

    ${query(MENU_BREAKPOINT)`
    display: inline-flex;
  `};

    ${props => props.thin && `top: 15px !important;`};
  }
`;

const SmallArrow = styled(Arrow)`
  && {
    fill: ${props => props.color};
    width: 14px;
    margin-left: 5px;
  }
`;

const MenuButton = styled(MenuIcon)`
  && {
    margin-right: 10px;
    color: inherit;
    ${query(MENU_BREAKPOINT)`
    display: none;
  `};
  }
`;

const PrimaryButton = styled(Button)`
  && {
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;

const SecondaryButton = styled(Button)`
  && {
    margin-bottom: 20px;
    ${query(MEDIUM_BREAKPOINT)`
      display: inline-flex;
    `};
  }
`;

const coronavirusMedium = 256;
const coronavirusXL = 200;

const FirstImageCard = styled(ImageCard)`
  && {
    width: calc(100% - 40px);
    margin: 20px auto;
    ${query(MEDIUM_BREAKPOINT)`
    position: absolute;
    right: 3%;
    top: calc(430px + ${coronavirusMedium}px + 100px);
    width: 45%;
  `}
    ${query(XL_BREAKPOINT)`
    top: calc(550px + ${coronavirusXL}px);
    right: 3%;
  `};
    ${query(1100)`
    right: 3%;
  `};
  }
`;

const SecondImageCard = styled(ImageCard)`
  && {
    display: none;
    ${query(MEDIUM_BREAKPOINT)`
      /* transform: rotate(-12deg); */
      display: block;
      width: 30%;
      position: absolute;
      top: calc(510px + ${coronavirusMedium}px + 50px);
      right: 58%;
    `} ${query(XL_BREAKPOINT)`
    top: calc(600px +${coronavirusXL}px);
    right: 58%;
  `};
  }
`;

const ThirdImageCard = styled(ImageCard)`
  && {
    display: none;
    ${query(MEDIUM_BREAKPOINT)`
    /* transform: rotate(-12deg); */
    display: block;
    width: 240px;
    position: absolute;
    top: calc(130px + ${coronavirusMedium}px + 50px);
    right: 0%;
    max-height: 288px;
  `} ${query(LARGE_BREAKPOINT)`
    right: 5%;
  `} ${query(XL_BREAKPOINT)`
    width: 335px;
    max-height: 335px;
    top: calc(160px + ${coronavirusXL}px);
  `};
  }
`;

// const Stripes = styled.div`
//   && {
//     height: 520px;
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     /* transform: skewY(-12deg); */
//     overflow: hidden;
//     transform-origin: 0;
//     width: 100%;
//     /* background: linear-gradient(
//       ${props => props.random + 150}deg,
//       #5533ff 15%,
//       #8205ff 50%,
//       #ffc1a6 94%
//     ); */
//     ${({ simpleBackground }) =>
//       simpleBackground && `background: ${simpleBackground}`};
//     ${({ short }) =>
//       !short &&
//       query(MEDIUM_BREAKPOINT)`
//         height: 700px;
//     `};
//     ${({ short }) =>
//       !short &&
//       query(XL_BREAKPOINT)`
//         height: 800px;
//       `};
//     ${({ short }) =>
//       short &&
//       query(MEDIUM_BREAKPOINT)`
//         height: 580px;
//       `};
//     ${({ short }) =>
//       short &&
//       query(XL_BREAKPOINT)`
//         height: 900px;
//       `};
//   }
// `;

// const Stripe1 = styled.span`
//   && {
//     width: 33%;
//     height: 125px;
//     background: #1fa2ff;
//     bottom: 25%;
//     position: absolute;
//   }
// `;

// const Stripe2 = styled.span`
//   && {
//     position: absolute;
//     top: 19px;
//     height: 53px;
//     background: #4c29ff;
//     width: 50%;
//     left: 40%;
//   }
// `;

// const Stripe3 = styled.span`
//   && {
//     position: absolute;
//     height: 40px;
//     bottom: 0px;
//     right: 0;
//     width: 25%;
//     background: #a1ffcb;
//   }
// `;

// const Stripe4 = styled.span`
//   && {
//     position: absolute;
//     height: 40px;
//     bottom: 40px;
//     right: 0;
//     width: 15%;
//     background: #2be7ff;
//   }
// `;

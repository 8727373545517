// @flow
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import FolderIcon from '@material-ui/icons/FolderShared';
import MapIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import CloseIcon from '../icons/X';
import { purple } from '../style/colors';
import { mapUrl, patientPortalLink, telUrl } from '../utils/constants';

const Menu = ({ closeMenu, style }: { closeMenu: () => any, style?: any }) => {
  return (
    <Wrapper style={style}>
      <GreyCardContent>
        <CloseButton onClick={closeMenu}>
          <CloseIcon />
        </CloseButton>
        <List>
          <StyledListItem
            button
            component="a"
            target="_blank"
            href={telUrl}
            // onClick={() => (window ? (window.location.href = telUrl) : null)}
          >
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <HeavyText primary="CALL US" secondary="(208) 855-2900" />
          </StyledListItem>
          <StyledListItem
            component="a"
            target="_blank"
            href={mapUrl}
            button
            // onClick={() => (window ? (window.location.href = mapUrl) : null)}
          >
            <ListItemIcon>
              <MapIcon />
            </ListItemIcon>
            <HeavyText primary="MAP" />
          </StyledListItem>
          <StyledListItem
            component="a"
            button
            target="_blank"
            href={patientPortalLink}
            // onClick={() =>
            //   window ? (window.location.href = patientPortalLink) : null
            // }
          >
            <ListItemIcon>
              <FolderIcon />
            </ListItemIcon>
            <HeavyText primary="PATIENT PORTAL" />
          </StyledListItem>
        </List>
      </GreyCardContent>
      <CardContent>
        <List>
          <NavLink to="/conditions">
            <NavText primary="See what we treat" />
          </NavLink>
          <NavLink to="/services">
            <NavText primary="Explore our services" />
          </NavLink>
          <NavLink to="/contact">
            <NavText primary="Contact and locations" />
          </NavLink>
          <NavLink to="/referral">
            <NavText primary="Providers: Make a referral" />
          </NavLink>

          <Subheader color="inherit">GET TO KNOW US</Subheader>

          <NavLink to="/philosophy-of-care">
            <NavText primary="Philosophy of care" />
          </NavLink>
          <NavLink to="/team">
            <NavText primary="Meet our team" />
          </NavLink>
          <NavLink to="/facilities">
            <NavText primary="See the facilities" />
          </NavLink>

          <Subheader color="inherit">THE PATIENT EXPERIENCE</Subheader>

          <NavLink to="/consultation">
            <NavText primary="Consultation" />
          </NavLink>
          {/* 
          <NavLink to="/second-opinion-program">
            <NavText primary="Second opinion program" />
          </NavLink> 
        */}
          <NavLink to="/procedure-and-recovery">
            <NavText primary="Procedure and recovery" />
          </NavLink>
          <NavLink to="/payment-and-insurance">
            <NavText primary="Payment and Insurance" />
          </NavLink>
        </List>
      </CardContent>
    </Wrapper>
  );
};
Menu.muiName = 'Card';

const Wrapper = styled(Card)`
  && {
    width: calc(100% - 20px);
    z-index: 500;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    margin-bottom: 30px;
  }
`;

const StyledListItem = styled(ListItem)`
  && {
    margin-bottom: 0;
  }
`;

const NavLink = ({ to, children, ...props }) => (
  <Link to={to}>
    <StyledListItem button {...props}>
      {children}
    </StyledListItem>
  </Link>
);

const Subheader = styled(ListSubheader)`
  && {
    color: ${purple[500]};
    padding-left: 0;
    font-size: 13px;
    margin-bottom: -11px;
    margin-top: 5px;
  }
`;

const NavText = styled(ListItemText)`
  && {
    h3 {
      color: ${purple[500]};
    }
  }
`;

const HeavyText = styled(ListItemText)`
  && {
    h3 {
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }
`;

const GreyCardContent = styled(CardContent)`
  && {
    position: relative;
    background: #ececec;
  }
`;

const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 400;
    width: 70px;
    height: 70px;
  }
`;

export default Menu;

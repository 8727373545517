export const mapUrl =
  'https://maps.apple.com/?daddr=360%20E%20Montvue%20Dr%20%23100%2C%20Meridian%2C%20ID%2083642';

export const telUrl = 'tel:1-208-855-2900';

export const patientPortalLink = 'https://10048.portal.athenahealth.com/';

export const REVIEW_LINK =
  'https://search.google.com/local/writereview?placeid=ChIJeeHHEkC0r1QRBIgL0NVOVFs';

export const infoEmail = 'info@spineidaho.com';
export const appointmentEmail = 'appointments@spineidaho.com';

export const GOOGLE_MAP_LINK =
  'https://www.google.com/maps/place/Spine+Institute+Of+Idaho/@43.6020356,-116.3864516,13z/data=!4m17!1m11!4m10!1m3!2m2!1d-116.3514327!2d43.6024707!1m5!1m1!1s0x54afb44012c7e179:0x5b544ed5d00b8804!2m2!1d-116.3514307!2d43.6016219!3m4!1s0x54afb44012c7e179:0x5b544ed5d00b8804!8m2!3d43.6016219!4d-116.3514307';

export const GOOGLE_MAP_LINK_2 =
  'https://www.google.com/maps/place/Spine+Institute+of+Idaho/@43.6529519,-116.6969377,17z/data=!3m1!4b1!4m5!3m4!1s0x54afb441bdd9daeb:0x8e8a9c32dd181ed3!8m2!3d43.652948!4d-116.694749';

export const GOOGLE_MAP_LINK_NAMPA =
  'https://www.google.com/maps/place/4424+E+Flamingo+Ave+%23230,+Nampa,+ID+83687/@43.5976867,-116.5178559,17z/data=!3m1!4b1!4m5!3m4!1s0x54ae4da2433b0aaf:0x156f5389f84485cc!8m2!3d43.5976867!4d-116.5156672';

export const IS_SERVER = typeof window !== undefined;

// @flow
import MDList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import React from 'react';
import styled from 'styled-components';
import newpatient from '../assets/Spine Idaho New Patient Form.pdf';
import paymentPolicy from '../assets/Spine Idaho Payment Policy.pdf';
import { MAX_WIDTH, MEDIUM_BREAKPOINT, query } from '../style';
import { blue } from '../style/colors';
import { infoEmail, patientPortalLink, telUrl } from '../utils/constants';
import Link, { navigateTo } from './GatsbyLink';

export default class Footer extends React.Component<any> {
  render() {
    return (
      <Wrapper>
        <MaxWidth>
          <Copywrite>© Spine Institue of Idaho</Copywrite>
          <List dense>
            <Section>
              <SubHeader>Spine Idaho </SubHeader>
              <Link to="/philosophy-of-care">
                <Item button dense disableGutters>
                  <Text disableTypography primary="Philosophy of care" />
                </Item>
              </Link>
              {/* <Item button dense disableGutters>
                <Text disableTypography primary="Careers" />
              </Item> */}
              <Link to="/privacy">
                <Item button dense disableGutters>
                  <Text disableTypography primary="Privacy policy" />
                </Item>
              </Link>
              <A href={paymentPolicy} target="_blank">
                <Item button dense disableGutters>
                  <Text disableTypography primary="Payment policy" />
                </Item>
              </A>
              <Link to="/nondiscrimination">
                <Item button dense disableGutters>
                  <Text disableTypography primary="Nondiscimination" />
                </Item>
              </Link>
            </Section>

            <Section>
              <SubHeader>Resources</SubHeader>
              <A href={patientPortalLink} target="_blank">
                <Item button dense disableGutters>
                  <Text disableTypography primary="Patient portal" />
                </Item>
              </A>
              <A href={newpatient} target="_blank">
                <Item button dense disableGutters>
                  <Text disableTypography primary="New patient forms" />
                </Item>
              </A>
              <Link to="/referral">
                <Item button dense disableGutters>
                  <Text disableTypography primary="Make a referral" />
                </Item>
              </Link>
              <A target="_blank" href="https://www.spine-health.com/">
                <Item button dense disableGutters>
                  <Text disableTypography primary="spine-health.com" />
                </Item>
              </A>
            </Section>
            <Section>
              <SubHeader>Contact</SubHeader>
              <A target="_blank" href={`mailto:${infoEmail}`}>
                <Item button dense disableGutters>
                  <Text disableTypography primary="Email us" />
                </Item>
              </A>
              <A
                target="_blank"
                onClick={() =>
                  window && window.innerWidth < 600
                    ? (window.location.href = telUrl)
                    : navigateTo('/contact')
                }
              >
                <Item button dense disableGutters>
                  <Text disableTypography primary="Call us" />
                </Item>
              </A>
              <Link to="/feedback">
                <Item button dense disableGutters>
                  <Text disableTypography primary="Leave us feedback" />
                </Item>
              </Link>
              <Link to="/contact">
                <Item button dense disableGutters>
                  <Text disableTypography primary="Locations and maps" />
                </Item>
              </Link>
            </Section>
          </List>
          <Contentful>
            <a
              href="https://www.contentful.com/"
              rel="nofollow"
              target="_blank"
            >
              <img
                src="https://images.contentful.com/fo9twyrwpveg/44baP9Gtm8qE2Umm8CQwQk/c43325463d1cb5db2ef97fca0788ea55/PoweredByContentful_LightBackground.svg"
                style={{ maxWidth: 100, width: '100%' }}
                alt="Powered by Contentful"
              />
            </a>
          </Contentful>
          <Hipaa>
            <HipaaItem>
              ATTENTION: If you speak English, language assistance services,
              free of charge, are available to you. Call{' '}
              <A href="tel:12088552900">1-208-855-2900</A>.
            </HipaaItem>
            <HipaaItem>
              ATENCIÓN: si habla español, tiene a su disposición servicios
              gratuitos de asistencia lingüística. Llame al{' '}
              <A href="tel:12088552900">1-208-855-2900</A>.
            </HipaaItem>
            <HipaaItem>
              注意：如果您使用繁體中文，您可以免費獲得語言援助服務。請致電{' '}
              <A href="tel:12088552900">1-208-855-2900</A>.
            </HipaaItem>
          </Hipaa>
        </MaxWidth>
      </Wrapper>
    );
  }
}

const HipaaItem = styled.p`
  && {
    margin-bottom: 0.5rem;
  }
`;

const Copywrite = styled.div`
  && {
    position: absolute;
    right: 0;
    margin-right: 15px;
    opacity: 0.5;
  }
`;

const Contentful = styled.div`
  && {
    margin-right: 15px;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
`;
const Hipaa = styled.div`
  && {
    color: ${blue[300]};
    font-size: 16px;
    line-height: 30px;
    margin-top: 70px;
    text-align: center;
  }
`;

const A = styled.a`
  && {
    text-shadow: none;

    &:hover {
      text-shadow: none;
    }
  }
`;

const List = styled(MDList)`
  && {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 20px;
  }
`;

const SubHeader = styled(ListSubheader)`
  && {
    font-size: 13px;
    text-transform: uppercase;
    padding-top: 5px;
    padding-bottom: 8px;
    line-height: 22px;
    margin-top: 20px;
    padding-left: 5px;
  }
`;

const Section = styled.div`
  && {
    width: 172px;
  }
`;

const Text = styled(ListItemText)`
  && {
    color: ${blue[300]};
    font-size: 16px;
    line-height: 30px;
  }
`;

const Item = styled(ListItem)`
  && {
    padding: 5px;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const MaxWidth = styled.div`
  && {
    max-width: ${MAX_WIDTH}px;
    margin: auto;
    padding: 20px;
    ${query(MEDIUM_BREAKPOINT)`
    padding-bottom: 100px;
    padding-top: 50px;
  `};
    position: relative;
  }
`;

const Wrapper = styled.div`
  && {
    background: #f6f9fc;
    border-top: 1px solid #d9e2ea;
  }
`;

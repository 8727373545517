//@flow
import React from "react";
import styled from "styled-components";

export default ({ thin }) => {
  if (thin) return null;
  return (
    <Wrapper>
      <Header style={{ fontSize: 24, marginTop: 0, color: "inherit" }}>
        Update on COVID-19 (Coronavirus)
      </Header>
      <Message style={{ fontSize: 18 }}>
        We are taking the threat of COVID-19 seriously. The safety of our
        patients and staff is our top priority. While our office remains open,{" "}
        <b>
          we now offer telemedicine appointments to limit in-office exposure.
        </b>{" "}
        Please call to schedule an appointment or with questions.
      </Message>
    </Wrapper>
  );
};

const Header = styled.h1`
  font-size: 24px;
  margin-top: 0;
  color: inherit;
`;

const Message = styled.p`
  font-size: 18px;
`;

const Wrapper = styled.div`
  margin: 20px;
  padding: 20px 20px;
  background: #eef2f7;
  border-radius: 4px;
`;
